import '../Styles/Testerlayout.css';
function Testerlayout(){
    return(
        <div>
            <div className="l1"> d1
                <h1 className="h1">h1</h1>
            </div>
            <div className="l2"> h2 </div>
            <div className="l1"> h3 </div>
            {/* <div class='vh-15 bg-red'>   1: 15% </div>
            <div class='vh-70 bg-green'> 2: 70% </div>
            <div class='vh-15 bg-blue'>  3: 15% </div> */}
        </div>
    );
}
export default Testerlayout